<template>
  <vx-card :title="title">
    <div class="vx-col mb-6 w-1/2">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Warehouse</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedWarehouse"
            :options="optionWarehouse"
            :multiple="true"
            track-by="id"
            :max-height="100"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLableWarehouse"
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :maxDate="new Date()"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="filterDate"
            :linkedCalendars="true"
            @select="(val) => saveDate(val)"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ datePickerDateFormat(picker.startDate) }} -
              {{ datePickerDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Driver</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedDriver"
            :options="optionDriver"
            :multiple="false"
            :max-height="100"
            track-by="id"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelDriver"
            @search-change="handleBTDriver"
            @select="handleBTSelectDriver"
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Shipment Number</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="shipmentNumber"
            :options="optionShipmentNumber"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="100"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelTP"
            @search-change="handleBTShipment"
            @select="(val) => saveShipmentNumber(val)"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Status Delivery</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedStatus"
            :options="optionStatus"
            placeholder="Type to search"
            :searchable="true"
            label="status"
            track-by="id"
            @select="(val) => saveStatus(val)"
          />
        </div>
      </div>
      <div class="vx-row mb-6 w-full">
        <div class="vx-col sm:w-1/4 w-full">
          <span></span>
        </div>
        <div class="vx-row sm:w-1/2 w-full space-x-2">
          <vs-button
            @click="onClickBtnFilter"
            size="small"
            color="primary"
            icon-pack="feather"
            icon="icon-search"
            title="Search"
          >
            Filter</vs-button
          >
          <vs-button
            @click="onClickBtnClearFilter"
            size="small"
            color="primary"
            icon-pack="feather"
            icon="icon-trash-2"
            title="clear"
          >
            Clear</vs-button
          >
        </div>
      </div>
    </div>
    <vs-tabs v-model="active">
      <vs-tab label="Manual">
        <Manual
          :transportPlanID="transportPlanID"
          :warehouseID="warehouseID"
          :driverName="DriverName"
          :dateStart="filterDate.startDate"
          :dateEnd="filterDate.endDate"
          :selectedStatus="selectedStatus"
          :isFilters="isfilter"
          @isFilters="setFilter"
        ></Manual>
      </vs-tab>
      <vs-tab label="EPOD">
        <div class="tab-text">
          <EPOD
            :transportPlanID="transportPlanID"
            :warehouseID="warehouseID"
            :driverName="DriverName"
            :dateStart="filterDate.startDate"
            :dateEnd="filterDate.endDate"
            :selectedStatus="selectedStatus"
            :isFilters="isfilter"
            @isFilters="setFilter"
          ></EPOD>
        </div>
      </vs-tab>
      <vs-tab label="Processed">
        <div class="tab-text">
          <Processed
            :transportPlanID="transportPlanID"
            :warehouseID="warehouseID"
            :driverName="DriverName"
            :dateStart="filterDate.startDate"
            :dateEnd="filterDate.endDate"
            :selectedStatus="selectedStatus"
            :isFilters="isfilter"
            @isFilters="setFilter"
          ></Processed>
        </div>
      </vs-tab>
      <vs-tab label="Cancel">
        <div class="tab-text">
          <Cancel
            :transportPlanID="transportPlanID"
            :warehouseID="warehouseID"
            :driverName="DriverName"
            :dateStart="filterDate.startDate"
            :dateEnd="filterDate.endDate"
            :selectedStatus="selectedStatus"
            :isFilters="isfilter"
            @isFilters="setFilter"
          ></Cancel>
        </div>
      </vs-tab>
      <vs-tab label="Redeliver">
        <div class="tab-text">
          <Redeliver
            :transportPlanID="transportPlanID"
            :warehouseID="warehouseID"
            :driverName="DriverName"
            :dateStart="filterDate.startDate"
            :dateEnd="filterDate.endDate"
            :selectedStatus="selectedStatus"
            :isFilters="isfilter"
            @isFilters="setFilter"
          ></Redeliver>
        </div>
      </vs-tab>
      <vs-tab label="EndSession">
        <div class="tab-text">
          <EndSession
            :transportPlanID="transportPlanID"
            :warehouseID="warehouseID"
            :driverName="DriverName"
            :dateStart="filterDate.startDate"
            :dateEnd="filterDate.endDate"
            :selectedStatus="selectedStatus"
            :isFilters="isfilter"
            @isFilters="setFilter"
          ></EndSession>
        </div>
      </vs-tab>
      <!-- <vs-tab label="Close">
        <div class="tab-text">
          <Close
            :transportPlanID="transportPlanID"
            :warehouseID="warehouseID"
            :date="date"
            :isFilters="isfilter"
            @isFilters="setFilter"
          ></Close>
        </div>
      </vs-tab> -->
    </vs-tabs>
  </vx-card>
</template>
<script>
import Manual from "./_tab/manual-tab.vue";
import EPOD from "./_tab/epod-tab.vue";
import Processed from "./_tab/processed-tab.vue";
import Cancel from "./_tab/cancel-tab.vue";
import Redeliver from "./_tab/redeliver-tab.vue";
import EndSession from "./_tab/end-session-tab.vue";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  props: {
    // active: {
    //   type: Number,
    // },
  },
  components: {
    Manual,
    EPOD,
    Processed,
    Cancel,
    Redeliver,
    EndSession,
    DateRangePicker,
  },
  mounted() {
    this.getWarehouse();
    this.getDriver();
    if (this.selectedDriver != null) {
      console.log("driver", this.selectedDriver);
      this.getShipmentNumber(this.table);
    }
  },
  computed: {
    shipmentNumber: {
      get() {
        return this.$store.state.pod.pod.shipmentNumber;
      },
      set(val) {
        this.$store.dispatch("pod/pod/updateShipmentNumber", val);
      },
    },
  },
  watch: {
    selectedWarehouse: {
      handler: function (val) {
        console.log("yyyyyyy", val);
        this.$store.dispatch("pod/pod/updateWarehouse", val);

        this.getDriver();
      },
      deep: true,
    },

    active: {
      handler: function (val) {
        console.log("active", val);
        this.$store.dispatch("pod/pod/updateTab", val);
      },
      deep: true,
    },
  },

  data() {
    return {
      table: this.tableDefaultState(),
      title: "Proof Of Delivery",
      warehouseID: null,
      filterDate: this.$store.state.pod.pod.date,
      transportPlanID: null,
      DriverName: "",
      optionShipmentNumber: [],
      selectedShipementNumber: this.$store.state.pod.pod.shipmentNumber,
      disableShipmentNumber: true,
      optionWarehouse: [],
      selectedWarehouse: this.$store.state.pod.pod.warehouse,
      selectedDriver: this.$store.state.pod.pod.driver,
      optionDriver: [],
      optionStatus: [
        {
          status: "Complete",
          id: 1,
        },
        {
          status: "Partial Complete",
          id: 3,
        },
        {
          status: "Failed Cancel",
          id: 4,
        },
        {
          status: "Failed Redeliver",
          id: 5,
        },
      ],
      selectedStatus: this.$store.state.pod.pod.statusDelivery,
      isfilter: false,
      active: this.$store.state.pod.pod.tab,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    setFilter(val) {
      this.isfilter = val;
    },
    cekLog(val) {
      console.log("yyyyyyy", val);
      this.$store.dispatch("pod/pod/updateWarehouse", val);
    },
    async getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouses", {
          params: {
            length: 1000,
            order: "name",
            sort: "asc",
            by_personal: 1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
              if (this.optionWarehouse.length > 0) {
                if (this.selectedWarehouse.length == 0) {
                  this.selectedWarehouse = [
                    {
                      code: this.$userLogin.warehouse_code
                        ? this.$userLogin.warehouse_code
                        : this.optionWarehouse[0].code,
                      name: this.$userLogin.warehouse_name
                        ? this.$userLogin.warehouse_name
                        : this.optionWarehouse[0].name,
                      id: this.$userLogin.warehouse_id
                        ? this.$userLogin.warehouse_id
                        : this.optionWarehouse[0].id,
                    },
                  ];
                  this.$store.dispatch(
                    "pod/pod/updateWarehouse",
                    this.selectedWarehouse
                  );
                }
                // this.selectedWarehouse = [
                //   {
                //     code: this.$userLogin.warehouse_code
                //       ? this.$userLogin.warehouse_code
                //       : this.optionWarehouse[0].code,
                //     name: this.$userLogin.warehouse_name
                //       ? this.$userLogin.warehouse_name
                //       : this.optionWarehouse[0].name,
                //     id: this.$userLogin.warehouse_id
                //       ? this.$userLogin.warehouse_id
                //       : this.optionWarehouse[0].id,
                //   },
                // ];
                // this.handleBTNWarehouse();
              } else {
                this.optionWarehouse = [];
                this.selectedWarehouse = [];
              }
            } else {
              this.optionWarehouse = [];
              this.selectedWarehouse = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getShipmentNumber(params) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/proof-of-delivery/transport-plan", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: params.search,
            order: this.table.order,
            sort: this.table.sort,
            warehouse_id: this.selectedWarehouse.map((e) => e.id),
            date: this.date,
            driver_name: this.selectedDriver ? this.selectedDriver.name : null,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionShipmentNumber = resp.data.records;
              if (this.optionShipmentNumber.length > 0) {
                // this.selectedShipementNumber = this.optionShipmentNumber[0];
              } else {
                this.optionShipmentNumber = [];
                this.selectedShipementNumber = {};
              }
            } else {
              this.optionShipmentNumber = [];
              this.selectedShipementNumber = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDriver() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/drivers", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
            warehouse_ids: this.selectedWarehouse.map((e) => e.id),
            date: this.date,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionDriver = resp.data.records;
              if (this.optionDriver.length > 0) {
                // this.selectedDriver = this.optionDriver[0];
              } else {
                this.optionDriver = [];
                this.selectedDriver = null;
              }
            } else {
              this.optionDriver = [];
              this.selectedDriver = null;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableWarehouse({ code, name }) {
      return `${code} ${name}`;
    },
    customLabelTP({ ShipmentNumber }) {
      return `${ShipmentNumber} `;
    },
    handleBTNWarehouse() {
      let params = {
        ...this.table,
      };

      let warehouseIDs = [];
      this.selectedWarehouse.forEach((element) => {
        warehouseIDs.push(element.id);
      });

      this.disableShipmentNumber = false;
      params.warehouse_id = warehouseIDs;

      this.getShipmentNumber(params);
    },
    handleBTShipment(query) {
      if (query.length < 3) {
        return;
      } else {
        let params = {
          ...this.table,
        };
        params.search = query;
        params.warehouse_id = this.selectedWarehouse.id;
        this.getShipmentNumber(params);
      }
    },
    handleBTDriver(query) {
      if (query.length < 3) {
        return;
      } else {
        let params = {
          ...this.table,
        };
        params.search = query;
        params.warehouse_id = this.selectedWarehouse.id;
        this.getDriver(params);
      }
    },
    saveDate(val) {
      this.$store.dispatch("pod/pod/updateDate", val);
      console.log("date", val);
    },
    saveShipmentNumber(val) {
      this.$store.dispatch("pod/pod/updateShipmentNumber", val);
      console.log("shipment", val);
    },
    saveStatus(val) {
      this.$store.dispatch("pod/pod/updateStatusDelivery", val);
      console.log("status", val);
    },
    handleBTSelectDriver() {
      this.$store.dispatch("pod/pod/updateDriver", this.selectedDriver);
      let params = {
        ...this.table,
      };
      params.warehouse_id = this.selectedWarehouse.id;
      params.driver_name = this.selectedDriver.name;
      this.getShipmentNumber(params);
    },
    customLabelDriver({ name }) {
      return `${name}`;
    },
    onClickBtnFilter() {
      this.transportPlanID = this.selectedShipementNumber
        ? this.selectedShipementNumber.id
        : null;
      this.warehouseID = this.selectedWarehouse[0].id;
      console.log("driver", this.selectedDriver);
      this.DriverName = this.selectedDriver ? this.selectedDriver.name : "";
      this.isfilter = true;
    },
    onClickBtnClearFilter() {
      this.transportPlanID = null;
      this.filterDate = {
        startDate: "",
        endDate: "",
      };
      this.selectedStatus = null;
      this.selectedDriver = null;
      this.DriverName = "";
      this.selectedShipementNumber = null;
      this.selectedWarehouse = [
        {
          code: this.$userLogin.warehouse_code
            ? this.$userLogin.warehouse_code
            : this.optionWarehouse[0].code,
          name: this.$userLogin.warehouse_name
            ? this.$userLogin.warehouse_name
            : this.optionWarehouse[0].name,
          id: this.$userLogin.warehouse_id
            ? this.$userLogin.warehouse_id
            : this.optionWarehouse[0].id,
        },
      ];
      this.isfilter = true;
    },
    datePickerDateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
  },
};
</script>
